(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/utils.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/utils.js');
"use strict";


const {
  productIds
} = svs.utils.products;
const {
  signIndexToOutcome,
  constants: {
    MaxOutcomeIndexes
  }
} = svs.components.sport.tipsenShared;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  hasValidOutcomePair
} = svs.components.poolBetting.utils;
const eventTypeBoardLength = {
  [EventTypeId.EVENT_1X2]: 3,
  [EventTypeId.GOAL_COUNT]: 8,
  [EventTypeId.RESULT]: 7
};
const outcomeMap = {
  1: 0,
  X: 1,
  2: 2
};

const hexadecimalLengthByBoardLength = {
  8: 1,
  13: 1,
  12: 3,
  18: 3,
  24: 3,
  26: 2
};
const nrOfPaddingZeros = {
  [EventTypeId.EVENT_1X2]: 0,
  [EventTypeId.GOAL_COUNT]: 2,
  [EventTypeId.RESULT]: 3
};
const getOutcomeCountForEvent = eventTypeId => {
  const outcomeCount = eventTypeBoardLength[eventTypeId];
  if (!outcomeCount) {
    throw new Error("Unsupported eventTypeId: ".concat(eventTypeId));
  }
  return outcomeCount;
};
const getHexadecimalLengthByBoard = board => {
  const hexadecimalLength = hexadecimalLengthByBoardLength[board.length];
  if (!hexadecimalLength) {
    throw new Error("Unsupported board length: ".concat(board.length));
  }
  return hexadecimalLength;
};
const compileRegex = count => new RegExp(".{1,".concat(count, "}"), 'g');

const calculateBestBetRows = function (srows, mSigns, uSigns) {
  let {
    cap = Infinity,
    productId
  } = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return function () {
    let resultOutcomes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const maxCorrects = resultOutcomes.length;
    const sortedSROWS = new Array(maxCorrects + 1).fill(0).map(() => []);
    let numberOfRows = 0;
    for (const srow of srows) {
      let correctOutcomes = 0;
      if (Array.isArray(resultOutcomes) && resultOutcomes.length > 0) {
        const correctOutcomesArr = srow.filter((outcome, index) => {
          const resultOutcome = resultOutcomes === null || resultOutcomes === void 0 ? void 0 : resultOutcomes[index];
          if (productId === productIds.BOMBEN) {
            return hasValidOutcomePair({
              outcomePairs: [outcome.join('-')],
              resultOutcome: resultOutcomes[index],
              maxOutcomeIndex: MaxOutcomeIndexes.BOMBEN
            });
          }
          return outcome.toString() === resultOutcome.toString();
        });
        correctOutcomes = correctOutcomesArr.length;
      }
      sortedSROWS[maxCorrects - correctOutcomes].push(srow);
      if (++numberOfRows === cap) {
        break;
      }
    }
    return sortedSROWS;
  };
};

const compressDecimalSeriesToHex = decimalString => decimalString.match(compileRegex(1)).reduce((hex, dec) => hex | 1 << Number(dec), 0).toString(16).toUpperCase();
const compressSROWToHex = function (board) {
  let {
    eventTypeId = EventTypeId.EVENT_1X2
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const padding = nrOfPaddingZeros[eventTypeId];
  if (eventTypeId === EventTypeId.EVENT_1X2) {
    return board.map(outcome => compressDecimalSeriesToHex(String(outcomeMap[outcome]))).join('');
  }
  if (eventTypeId === EventTypeId.RESULT) {
    const hexRows = board.map(board => {
      const hexRow = [];
      board.forEach(signs => {
        const hexSigns = [];
        signs.forEach(sign => {
          const currentSign = sign === 'F' ? 10 : sign;
          hexSigns.push((1 << currentSign).toString(16).padStart(padding, 0));
        });
        hexRow.push(hexSigns.join(''));
      });
      return hexRow.join('');
    });
    return hexRows;
  }
  return board.map(outcome => compressDecimalSeriesToHex(String(outcome)).padStart(padding, '0')).join('');
};
const compressMatrixToMathRow = (matrix, _ref) => {
  let {
    eventTypeId = EventTypeId.EVENT_1X2
  } = _ref;
  return matrix.map(row => row.map((outcome, index) => outcome ? index : '').map(sign => signIndexToOutcome[eventTypeId][sign]).join(''));
};

const calculateMsystem = function (noSelectedMatches) {
  let matchesNoSystem = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
  let lowerSystemBound = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 9;
  let higherSystemBound = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 14;
  const factorialOfMatchesNoSystem = svs.utils.math.Factorial(matchesNoSystem);
  const diff = noSelectedMatches - matchesNoSystem;
  if (noSelectedMatches < lowerSystemBound || noSelectedMatches > higherSystemBound) {
    throw new Error("M".concat(noSelectedMatches, " is not a valid M-system"));
  }
  return svs.utils.math.Factorial(noSelectedMatches) / (factorialOfMatchesNoSystem * svs.utils.math.Factorial(diff));
};
const convertScoreboardDataToSign = boardData => {
  const scoreSign = boardData === 'F' ? MaxOutcomeIndexes.BOMBEN : Math.min(parseInt(boardData, 10));
  return scoreSign;
};
const convertOutcomePairsToBoardData = outcomePairsForAllEvents => {
  const convertedOutcomePairs = [];
  outcomePairsForAllEvents.forEach(outcomePairsForEvent => {
    const homeTeamSigns = new Set();
    const awayTeamSigns = new Set();
    outcomePairsForEvent.forEach(outcomePair => {
      outcomePair.forEach((sign, index) => {
        if (index % 2 !== 0) {
          awayTeamSigns.add(sign);
        } else {
          homeTeamSigns.add(sign);
        }
      });
    });
    convertedOutcomePairs.push(Array.from(homeTeamSigns));
    convertedOutcomePairs.push(Array.from(awayTeamSigns));
  });
  return convertedOutcomePairs;
};
setGlobal('svs.components.tipsen.expandedSystems.utils', {
  calculateBestBetRows,
  calculateMsystem,
  compileRegex,
  compressDecimalSeriesToHex,
  compressMatrixToMathRow,
  compressSROWToHex,
  convertOutcomePairsToBoardData,
  convertScoreboardDataToSign,
  getOutcomeCountForEvent,
  getHexadecimalLengthByBoard,
  outcomeMap
});

 })(window);